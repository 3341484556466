.firstSectionContainer {
  background: url('assets/images/about_bg.png');
  background-color: #1893AF;
  background-size: cover;
}

.textShadow{
  font-weight: bold;
  color: var(--white);
  text-shadow: -1px 1px 0px #0F0F0F;
}

.center {
  text-align: center;
}

.section {
  background-color: var(--light-blue);
  padding: var(--section-padding);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: flex-end;
}

.container {
  width: 100%;
  max-width: var(--max-section-width);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.section p {
  padding-bottom: calc(var(--grid-baseline)*4);
}


/* About Section */
.about {
  padding-top: calc(2 * var(--grid-baseline));
  padding-bottom: 0px;
  background-color: transparent;
  color: var(--white)
}

.accent {
  font-size: var(--subheading-font-size);
  font-weight: bold;
}

.textWithImageContainer {
  display: grid;
  align-items: stretch;
  color: var(--white);
}

.imageRight {
  grid-template-columns: 1fr 250px;
}

.imageRight div:first-of-type {
  padding-right: 48px;
}

.imageLeft {
  grid-template-columns: 250px 1fr;
  align-items: flex-end;
}

.imageLeft div:first-of-type{
  padding-right: 48px;
}

.aboutImageContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 250px;
}

.about .birdsTop {
  width: 60%;
}
.about .rightMeemo {
  max-width: 100%;
}
/* Application */
.app {
  background: var(--light-blue);
}

.gallery {
  margin-top: 24px;
  margin-bottom: 24px;
  height: 460px;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  white-space:nowrap;
}

.gallery img {
  max-height: 400px;
  margin: 8px;
  display:inline-block;
  vertical-align:top;
}

/* Meet the team */

.teamContainer {
  text-align: center;
  background-color: var(--light-yellow);
}

.teamContainer h1:first-of-type {
  padding-top: 0px;
}

.teamContainer h1 {
  padding-top: 32px;
}

.teamCardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: calc(var(--grid-baseline) * 4)
}

/* Roadmap */

.roadmapContainer {
  width: 100%;
  max-width: 1208px;
  margin-top: 24px;
}

.stepContainer{
  display: flex;
  flex-direction: row;
  position: relative;
  width: 47%;
  z-index: 2;
}

.mapContentContainer img {
  max-height: 134px !important;
  max-width: 250px !important;
}

.rightArrow {
  position: absolute;
  bottom: 0px;
  transform: translateY(100%);
  right: -40px;
}

.leftArrow {
  position: absolute;
  bottom: 0px;
  transform: translateY(100%);
  left: -40px;
}

.sideText {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
}

.mapContentContainer {
  background-color: white;
  padding: 16px;
  border-radius: 10px;
}

.sideText {
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #1893AF;
  line-height: 24px;
  font-weight: bold;
  text-align: center;
}

.sideText h3 {
  transform: rotate(270deg);
}

.mapContentContainer .sideText {
  display: none;
}

.mapContainer {
  display: flex;
}

.imageContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 99
}

.roadmapContainer img{
  max-width: 45%;
  max-height: 250px;
}

.mapContainer h2 {
  font-size: var(--subheading-font-size);
  text-align: center;
  font-style: italic;
}

.section.roadmap { 
  background-image: url('assets/images/rainbow.png');
  background-repeat: no-repeat;
  background-size: cover;
}

/* Footer */
.footer {
  background-color: var(--indigo);
}

@media screen and (min-width: 1036px) {
  .gallery {
    display: flex;
    justify-content: center;
    overflow: auto;
    overflow-y:hidden;
    overflow-x: hidden;
  }
}

@media screen and (max-width: 992px) {
  .stepContainer {
    width: 100%;
    padding-bottom: 24px;
  }

  .stepContainer .sideText {
    display:none;
  }

  .mapContainer img {
    display: none;
  }

  .mapContentContainer .sideText {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .imageContainer {
    display: none;
  }
}
@media screen and (max-width: 1025px) {
  .textWithImageContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 24px;
    text-align: center;
  }

  .imageLeft {
    flex-direction: column-reverse;
    align-items: center;
  }

  .imageLeft .aboutImageContainer {
    padding-top: 24px;
  }

  
  .imageRight div:first-of-type, .imageLeft div:first-of-type {
    padding-right: 0px;
  }

  .aboutImageContainer {
    width: 60%;
  }
}
@media screen and (max-width: 768px) {

  .teamCardsContainer {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 488px) {
  .teamCardsContainer {
    grid-template-columns: 1fr;
  }
}