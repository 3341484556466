.container {
  padding-bottom: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: var(--section-padding);
  padding-right: var(--section-padding);
}

.headerContainer {
  width: 100%;
  max-width: var(--max-section-width);
}

.topContainer {
  padding: 32px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.center {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
}

.mint {
  padding: calc(2 * var(--grid-baseline)) calc(4 * var(--grid-baseline));
  background-color: var(--white);
  border-radius: 50px;
  text-align: center;
  font-size: var(--subheading-font-size);
  position: relative;
  color: var(--ocean-blue);
  font-weight: bold;
  text-decoration: none;
  font-size: var(--body-font-size);
}

.socialButtonsContainer {
  display: flex;
  flex-direction: row;
}

.socialButton {
  display: flex;
  margin-right: 16px;
}

.socialButton svg {
  height: 38px !important;
  width: 38px !important;
}

.socialButton span {
  background: var(--white);
  color: var(--white);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
}

.socialButton svg {
  fill: var(--ocean-blue) !important;
  color: var(--ocean-blue) !important;
}

.socialButton span:hover {
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 2px 8px rgb(255, 255, 255);
}

@media screen and (max-width: 768px) {
  .container {
    background-color: var(--ocean-blue);
    padding-bottom: 32px;
  }

  .topContainer .socialButtonsContainer {
    display: none;
  }

  .mint {
    display: none;
    right: -14px;
  }

  .center {
    justify-content: center;
  }

  .container {
    padding-bottom: 0px;
  }
}
