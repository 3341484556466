.container {
    display: flex;
    align-items: center;
    background-color: white;
    justify-content: space-between;
    border-radius: 50px;
    transition: all .3s ease-in-out;
}

.container:hover {
    box-shadow: 0px 2px 8px rgb(255, 255, 255);
    transition: all .3s ease-in-out;
}

.link {
    color: var(--black);
    text-decoration: none;
    display: flex;
    justify-content: center;
    flex: 1;
    align-items: center;
    padding: 16px;
    transition: all .3s ease-in-out;
}

.link:hover, .link:hover svg{
  color: var(--ocean-blue);
  transition: all .3s ease-in-out;
}

.link span:first-child {
    padding-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobileLinksContainer {
  display: flex;
  margin-top: -16px;
}

.mobileLinks {
  display: none;
  flex-direction: column;
  z-index:999;
  background-color: var(--white);
  max-height: 0px;
  overflow:hidden;
  justify-content: center;
  align-items: center;
  transition: max-height .5s cubic-bezier(0.07, 0.55, 0.26, 0.97);
  width: 100%;
}

.mobileLinks.open {
  max-height: 800px;
  border-left: 2px solid var(--primaryColor);
  transition: max-height .5s cubic-bezier(0.4, 0.08, 1, 0.55);
}

.iconContainer {
    display: none;
    width: 48px;
    height: 38px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    transform: scale(0.5);
}

.iconContainer span {
    display: block;
    position: absolute;
    height: 6px;
    width: 50%;
    background: var(--white);
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  
.iconContainer span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
  }
  
  .iconContainer span:nth-child(odd) {
    left:0px;
    border-radius: 9px 0 0 9px;
  }
  
  .iconContainer span:nth-child(1), .iconContainer span:nth-child(2) {
    top: 0px;
  }
  
  .iconContainer span:nth-child(3), .iconContainer span:nth-child(4) {
    top: 18px;
  }
  
  .iconContainer span:nth-child(5), .iconContainer span:nth-child(6) {
    top: 36px;
  }
  
  .iconContainer.open span:nth-child(1),.iconContainer.open span:nth-child(6) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  .iconContainer.open span:nth-child(2),.iconContainer.open span:nth-child(5) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  .iconContainer.open span:nth-child(1) {
    left: 5px;
    top: 7px;
  }
  
  .iconContainer.open span:nth-child(2) {
    left: calc(50% - 5px);
    top: 7px;
  }
  
  .iconContainer.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }
  
  .iconContainer.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }
  
  .iconContainer.open span:nth-child(5) {
    left: 5px;
    top: 21px;
  }
  
  .iconContainer.open span:nth-child(6) {
    left: calc(50% - 5px);
    top: 21px;
  }

@media screen and (max-width: 768px) {
  .container {
    width: calc(100% - (2 * var(--section-padding)));
    margin-left: var(--section-padding);
    margin-right: var(--section-padding);
  }

  .iconContainer {
    display: block;
  }
  .mobileLinks {
    display: flex;
  }
  .container {
    display: none;
  }
}