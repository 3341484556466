.container {
    background-color: var(--white);
    max-height: 80px;
    overflow: hidden;
    border-radius: 16px;
    margin-bottom: 16px;
    transition: max-height .5s cubic-bezier(0.07, 0.55, 0.26, 0.97);
}

.expand {
    max-height: 1800px;
    transition: max-height .5s cubic-bezier(0.4, 0.08, 1, 0.55);
}

.container span {
    padding-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    font-size: var(--subheading-font-size);
    font-weight: bold;
    height: 80px;
    display: flex;
    align-items: center;
    padding: 16px;
}

.details {
    padding: 16px;
}