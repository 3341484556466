.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  max-width: 256px;
  text-align: center;
  padding: 16px 16px;
}

.circleImg {
  max-width: 130px;
  border-radius: 50%;
  margin-bottom: 24px;
}

.role {
  text-transform: uppercase;
  color: #747474;
  font-size: var(--subheading-font-size);
  font-weight: 500;
}

.subtitle {
  font-size: var(--subheading-font-size);
  font-weight: bold;
}

.container p {
  padding-bottom: var(--grid-baseline);
}

.socialButtons {
  margin-top: var(--grid-baseline);
  display: flex;
  flex-direction: row;
}

.socialButtons svg {
  height: 48px !important;
  width: 48px !important;
  border: 3px dashed black;
  border-radius: 50%;
  padding: 5px;
  margin-right: 8px;
}
