:root {
  --section-padding: 68px;
  --light-blue: #b3f1ff;
  --ocean-blue: #2A86BE;
  --indigo: #b3c8ff;
  --pink: #FDB3FF;
  --white: #ffffff;
  --black: #000000;
  --light-yellow: #FDFFB3;
  --light-green: #C7FFB3;
  --heading-font-size: 48px;
  --subheading-font-size: 28px;
  --body-font-size: 21px;
  --grid-baseline: 8px;
  --primary-font: 'Mali', cursive;
  --heading-font: 'Gaegu', cursive;
  --max-section-width: 1140px;
  --medium-max-breakpoint: 992px;
  --small-device-breakpoint: 768px;
}

/* width */
::-webkit-scrollbar {
  width: 20px;
  padding: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--white);
  border: 2px solid white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--light-blue);
  border: 4px solid white;
  border-radius: 16px;
  transition: all .5s ease-in-out;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--indigo);
  transition: all .5s ease-in-out;
}

.tooltipContainer {
  position: relative;
  cursor: pointer;
  opacity: 0.9;
}

.tooltip {
  position: absolute;
  opacity: 0;
  bottom: 0px;
  padding: 8px;
  border-radius: 8px;
  background-color: var(--pink);
  left: 50%;
  transform: translate(-50%, calc(100% + 16px));
  width: 100px; /* Need a specific value to work */
  transition: .3s all ease-in-out;
}

.tooltipContainer:hover .tooltip {
  opacity: 1;
  transition: .3s all ease-in-out;
}

.tooltip:after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 14px solid transparent;
  border-bottom-color: var(--pink);
  border-top: 0;
  transform: translate(-100%);
  margin-left: 13px;
  margin-top: -12px;
}

h2 {
  font-size: var(--subheading-font-size);
  font-family: var(--primary-font);
}

* {
  margin: 0;
  box-sizing: border-box;
  font-family: var(--primary-font);
  font-size: var(--body-font-size);
}

@keyframes append-animate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.back_to_top {
  position: fixed;
  bottom: var(--section-padding);
  right: var(--section-padding);
  padding: 16px;
  background-color: white;
  border: none;
  animation: append-animate 0.3s linear;
  border-radius: 50%;
  box-shadow: 0px 2px 8px rgb(255, 255, 255);
  z-index: 9999;
}

.back_to_top:hover {
  cursor: pointer;
}

.back_to_top span {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  :root {
    --section-padding: 16px;
    --heading-font-size: 36px;
    --subheading-font-size: 24px;
    --body-font-size: 18px;
    --grid-baseline: 4px;
  }
}
